.content {
  padding: 20px;
}

.entityTransfer {
  padding: 20px;
  justify-content: center;
}

.headerSubtitle {
  padding: 20px;
  font-size: 14px;
}