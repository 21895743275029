.container {
  border-top: 2px transparent;
}

.innerContainer {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}