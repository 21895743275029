.content {
  padding: 20px;
}

.details {
  margin-top: 25px;
}

.achievements {
  margin-top: 25px;
}

.userDataContainer {
  display: flex;
  padding: 7px;
}

.userDataLabel {
  margin-right: 10px;
  width: 140px;
  text-align: right;
}

.userDataField {
  font-weight: bold;
}
