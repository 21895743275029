$siderWidth: 200px;
$topbar-height: 111px;

.workspace {
  height: calc(100vh - #{$topbar-height}) ;
  width: calc(100% - #{$siderWidth}) !important;
  // position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  left: 0;

  display: inline-block
}

.sider {
  width: $siderWidth !important;
  overflow: auto;
  height: calc(100vh - #{$topbar-height}); // todo - 50px == header height
  // position: fixed;
  right: 0;

  border-left: 1px solid rgb(240, 240, 240);

  display: inline-block
}

.addButtonWrapper {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
