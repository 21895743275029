.container {
  width: 100%;
  height: 2px;
  
  transition: background-color 0.2s ease;
}

.active {
  background-color: #1890ff;
}

.disabled {
  background-color: transparent;
}