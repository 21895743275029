.wrapper {
    padding: 25px;

    .title {
        margin-bottom: 25px;

        font-family: SF Pro Text;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 137% ;

        color: #262626;
    }
}