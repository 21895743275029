.container {
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
}

.actionButton {
    margin-left: 10px;
}

.deleteButton {
    margin-left: auto;
}
