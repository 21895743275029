.container {
    width: 100%;
    display: flex;
    align-items: center;
}

.actionButton {
    margin-left: 10px;
}

.buttonContainer {
    display: flex;
    transition: opacity ease 0.3s;
    align-self: flex-start;
}

.deleteButton {
    margin-left: auto;
}
