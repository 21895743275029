.brandName {
    font-size: 20px;
    font-weight: 600;
    color: #262626;
    margin-left: 10px;
}

.shortDescription {
    margin-top: 1em;
}

.brandColors {
    .brandColorsTitle {
        margin-top: 1em;
        font-weight: 600;
    }

    .brandColorItem {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .brandColorItemCircle {
            width: 32px;
            height: 32px;
            content: '';
            display: inline-block;

            border-radius: 100%;
        }
        .brandColorItemText {
            margin-left: 10px;
            font-size: 14px;
            text-transform: uppercase;
            font-family: monospace;
        }

        & + & {
            margin-top: 100px;
        }
    }

    margin-bottom: 20px;
}

.emptyAvatar {
    background: white;
    color: lightgray;
    border: 1px solid lightgray;
}