.switchLabel {
    margin-right: 15px;
}

.dropdownMenuSwitchableItem {
    display: flex;
    align-items: center
}

.panelButton {
    height: 100%;
}