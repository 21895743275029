.container {
    position: relative;
    width: 100%;    

    $stripeSize: 5px;
    $stripeColor: rgba(#3C64B1, 0.1);
    background-color: red;
    background: repeating-linear-gradient(
        45deg,
        white,
        white $stripeSize,
        $stripeColor $stripeSize,
        $stripeColor $stripeSize + 2px
    );
    border-radius: 5px;

    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    .iconWrapper {
        $fontSize: 20px;

        position: absolute;
        width: 100%;
        top: calc(50% - #{$fontSize});

        text-align: center;
        color: #3C64B1;
        font-size: $fontSize;
    }
}
  
.title {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    margin-top: 8px;
    margin-bottom: 12px;
}
