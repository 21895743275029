.alert {
    margin-top: 20px;
}

.resendIcon {
    font-size: 18px;
    vertical-align: sub;
}

.resendButton {
    text-decoration: underline !important;
    padding-left: 5px;
    padding-right: 5px;
}


.buttonsLine {
    .buttonsExtra {
        display: inline-block;
    }
    .submitButton {
        float:right
    }
}

.extraUnderInputActionWrapper {
    margin-bottom: 10px;
}
