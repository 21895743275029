
.cardTitle {
  display: flex;
  justify-content: space-between;
}

.cardOptions {
  display: flex;
  align-items: flex-start;
  margin-top: 0.3em;
}

.cardText {
  margin-right: 0.4em;
}
