.alert {
    margin-top: 20px;
}

.underInputActionWrapper {
    margin-bottom: 10px;
}

.buttonsLine {
    .buttonsExtra {
        display: inline-block;
    }
    .submitButtonWrapper {
        float:right
    }
}