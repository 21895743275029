.elBreadcrumb {
    background-color: #F8F8F8;
    height: 56px;
    line-height: 56px;
    padding-left: 25px;
}

.elBreadcrumbItem {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
}