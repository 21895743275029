.content {
  padding: 20px;
}

.csvButton {
  float: right;
}

.langFilter {
  float: right;
  margin-right: 10px;

  button {
    margin-right: 10px;
  }
}