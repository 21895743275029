.elHeader {
    background-color: #F8F8F8;
    height: 50px;
    line-height: 50px;
}

.headerLogo {
    display: flex;
    align-items: center;
}

.headerTitle {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.85);
}