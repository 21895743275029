.container {
    background-color: white;
    width: 250px;

    height: calc(100vh - 50px); // 50px = header 
    overflow: hidden;
    border-right: 1px solid rgb(240, 240, 240);

    &:hover {
        .menu {
            overflow-y: auto;
        }
    }

    .menu {
        height: auto;
        max-height: calc(100vh - 50px - 52px); // 50 for header, and 50 for search-box
        overflow: hidden;

        border-right: none;
    }
}

.searchInput {
    width: 100%;
    padding: 10px 15px;
}

.searchInputActive {
    input, span > span {
        background-color: lightyellow;
    }
}
