.file-uploader {
  display: flex;

  .ant-upload-list-text-container {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 5px;

    .ant-upload-list-item {
      margin-top: 0;
    }
  }
}

.isFilePublic {
  display: flex;
  margin-bottom: -30px;
  margin-top: 25px;
}

.isFilePublicText {
  margin-left: 10px;
  margin-right: 10px;
}
