.container {
    width: 100%;
    display: flex;
    align-items: center;
}

.deleteButton {
    margin-left: auto;
}

.iframeWrapper {
    width: 800px;
    height: 600px;
}

.iframeStyle {
    width: 100%;
    height: 100%;
}