@import '~antd/dist/antd.css';
@import './components/fileUploader/fileUploader.scss';

.modules-sider .ant-table-tbody > tr > td {
  border-bottom: 0px solid #ffffff;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.section-panel-collapse .section-panel-panel > div.ant-collapse-header {
  & > span.anticon {
    height: 100%;
    display: flex;
    align-items: center;
    top: 0;
    padding-top: unset;
  }

  & > div.localizable-readonly {
    display: inline-block;
  }
}
